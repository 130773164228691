import React from "react";

const ProjectSection = ({id, content }) => {
  
    return (
    <section id={id}>
      <div className="project-page-container">
        {content.map((component, index) => (
          <div key={index}>{component}</div>
        ))}
      </div>
    </section>
  );

};

export default ProjectSection;