import React from 'react';
import './Footer.css';

const Footer = () => {

    const items = [
        'Lisa Khmyz',
        'Myrtle Beach, SC',
        '+1 (843) 331 8232',
        'yelyzavetakhmyz@gmail.com'
    ];

    return (
        <div className='footer-container'>
            <p className='footer-content'>
                {items.map((item, index) => (
                    <span key={index}>{item}</span>
                ))}
            </p>
        </div>
    );
};

export default Footer;