import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import Button from "../button/Button";

const Header = () => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const closeMenu = () => setMenuOpen(false);

    const handleEmailClick = () => {
        window.location.href = 'mailto:yelyzavetakhmyz@gmail.com';
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/" className="logo-link">
                    <h2 className="logo">
                        Lisa <br /> Khmyz
                    </h2>
                </Link>
            </div>
            `<nav className={`nav-links ${menuOpen ? "open" : ""}`}>
                <ul>
                <li className="text-link">
                    <Link
                        to="/"
                        className={location.pathname === "/" ? "active" : ""}
                        onClick={closeMenu}>
                            About
                    </Link>
                </li>
                <li className="text-link">
                    <Link
                    to="/projects"
                    className={location.pathname.startsWith("/projects") ? "active" : ""}
                    onClick={closeMenu}>
                        Projects
                    </Link>
                </li>
                <li className="button-link">
                    <Button
                        text="Contact"
                        className="header-button"
                        onClick={handleEmailClick}
                    ></Button>
                </li>
                </ul>
            </nav>
            <label className="check">
                <input
                    type="checkbox"
                    id="check"
                    checked={menuOpen}
                    onChange={toggleMenu}
                />
                <span></span>
                <span></span>
                <span></span>
            </label>
        </header>
    );
};

export default Header;