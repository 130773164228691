import MainPage from "./pages/main-page/MainPage";
import "./App.css"

function App() {

    return (
        <MainPage></MainPage>
    );
}

export default App;
