import IntroSection from "./sections/intro-seciton/IntroSection";
import AboutSection from "./sections/about-seciton/AboutSection";
import SkillsSection from "./sections/skills-section/SkillsSection";
import ProjectsSection from "./sections/projects-section/ProjectsSection";
import Footer from "../../components/footer/Footer";
import "./AboutPage.css"

const AboutPage = () => {
  return (
    <div className='main-cotainer'>
        <IntroSection></IntroSection>
        <AboutSection></AboutSection>
        <SkillsSection></SkillsSection>
        <ProjectsSection></ProjectsSection>
        <Footer></Footer>
    </div>
  );
}

export default AboutPage;