import React from "react";
import "./ProjectsSection.css";
import ProjectWidget from "../../../../components/project-widget/ProjectWidget";
import { projectsData } from "../../../../data/ProjectsData";

const ProjectsSection = () => {

    const rows = [];
    for (let i = 0; i < projectsData.length; i += 2) {
      rows.push(projectsData.slice(i, i + 2));
    }
  
    return (
      <div className="ps-main-wrapper">
        <h1 className="ps-header">Checkout my <span>Recent Projects</span></h1>
        <div className="grid-container-overlap">
          {rows.map((row, rowIndex) => (
            <div className="grid-container" key={`row-${rowIndex}`}>
              {row.map((project) => (
                <div className="grid-item" key={project.key}>
                  <ProjectWidget
                    header={project.header}
                    description={project.description}
                    avatar={project.avatarSRC}
                    navigateTo={`/projects/${project.key}`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ProjectsSection;