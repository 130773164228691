import React from 'react';
import PropTypes from 'prop-types';
import './Button.css'; 

const Button = ({ avatar, text, onClick, type = 'button', className = '', disabled = false, isActive = false }) => {
    return (
        <button
            type={type}
            className={`btn ${className} ${isActive ? 'active' : ''} ${avatar ? 'with-avatar' : ''}`}
            onClick={onClick}
            disabled={disabled}>
            
            {avatar && (
                <img src={avatar} alt="icon" className="button-avatar" />
            )}
            
            <span className={`button-text ${avatar ? 'with-avatar' : ''}`}>{text}</span>
            
        </button>
  );
};

Button.propTypes = {
    text: PropTypes.string.isRequired, 
    onClick: PropTypes.func.isRequired, 
    type: PropTypes.oneOf(['button', 'submit', 'reset']), 
    className: PropTypes.string, 
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
};

export default Button;