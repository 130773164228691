import React from "react";
import "./IntroSection.css";

const IntroSection = () => {
  return (
    <div className="main-wrapper">
        <div className="intro-section-overlap">
            <div className="intro-section-container">
                    <div className="text-container">
                    <h1>
                        Hi, my <br />
                        name is <span>Lisa Khmyz</span>
                    </h1>
                    <p> 
                        I am a motivated Computer Science graduate enthusiastic about 
                        embracing new challenges and expanding my knowledge
                        in software development.
                    </p>
                    </div>
                    <div className="image-section">
                        <img src="/assets/pages/about-page/intro-avatar.svg" alt="Avatar"/>
                    </div>
                </div>
        </div>
    </div>
    

  );
};

export default IntroSection;