import React from "react";
import ProjectSection from "../project-section/ProjectSection";
import "./ProjectPageTemplate.css";

const ProjectPageTemplate = React.memo(({ avatar, header, description, sections}) => {
    return (
        <div className="project-page-template-container">
            <div className="rectangle">
                <div className="circle">
                    <img src={avatar} alt="Icon" />
                </div>
            </div>
            <div className="content">
                <h1>{header}</h1>
                <p>{description}</p>
                {sections.map((section) => (
                    <ProjectSection 
                        id={section.id} 
                        content={section.content} 
                    />
                ))}
            </div>
        </div>
    );
});
  
export default ProjectPageTemplate;