import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import './ProjectWidget.css'; 

const ProjectWidget = ({ header, description, avatar, navigateTo }) => {
    const [navigate, setNavigate] = useState(false);

    const handleClick = () => {
        setNavigate(true);
    };

    if (navigate) {
        return <Navigate to={navigateTo} replace />;
    }
  
    return (
        <div className='project-widget-wrapper' onClick={handleClick}>
            <div className='project-widget'>
                <div className='project-widget-container'>
                    <div className='text-container'>
                        <h1>{header}</h1>
                        <p>{description}</p>
                    </div>
                    <div className="image-container">
                        <img src={avatar} alt="Avatar"/>
                    </div>
                </div>
                <p className='learn-more-label'>
                    Click to Learn more
                </p>
            </div>
        </div>
    );
};

export default ProjectWidget;