import React, { useState, useRef } from 'react';
import './SkillsSection.css';

const SkillsSection = () => {

    const [isDragging, setIsDragging] = useState(false);
    const [filter, setFilter] = useState("All");
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const containerRef = useRef(null);

    const skills = [
        { name: "React.Js", category: "FrontEnd" },
        { name: "Java", category: "BackEnd" },
        { name: "Python", category: "BackEnd" },
        { name: "Figma", category: "UI/UX" },
        { name: "SpringBoot", category: "BackEnd" },
        { name: "Maven", category: "BackEnd" },
        { name: "SQL", category: "BackEnd" },
        { name: "PostgreSQL", category: "BackEnd" },
        { name: "MongoDB", category: "BackEnd" },
        { name: "Docker", category: "BackEnd" },
        { name: "HTML", category: "FrontEnd" },
        { name: "CSS", category: "FrontEnd" },
        { name: "JS", category: "FrontEnd" },
        { name: "Adobe Photoshop", category: "UI/UX" },
    ];

    const handleMouseDown = (e) => {
        setIsDragging(true);
        startX.current = e.clientX;
        scrollLeft.current = containerRef.current.scrollLeft;
        e.preventDefault();
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.clientX;
        const walk = (x - startX.current) * 2; 
        containerRef.current.scrollLeft = scrollLeft.current - walk;
    };

    const scroll = (direction) => {
        const scrollAmount = 500; 
        
        containerRef.current.scrollLeft += direction * scrollAmount;
    };

    const filteredSkills =
        filter === "All" ? skills : skills.filter(skill => skill.category === filter);

    return (
        <div className="scroll-container-wrapper">
            <div className="background-text">
                My<br />
                Skills
            </div>
            <div
                ref={containerRef}
                className={`scroll-container ${isDragging ? "grabbing" : ""}`}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeave}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}>
                    <div className="scroll-content">
                    {filteredSkills.map(skill => (
                        <span key={skill.name}>{skill.name}.</span>
                    ))}
                    </div>
            </div>
            <div className='cont'>
                <div className="scroll-arrows">
                    <button className="arrow-left" onClick={() => scroll(-1)}>
                        <img src="/assets/pages/about-page/left-arrow.svg" alt="Left Arrow" width="30" height="30" />
                    </button>
                    <button className="arrow-right" onClick={() => scroll(1)}>
                        <img src="/assets/pages/about-page/right-arrow.svg" alt="Right Arrow" width="30" height="30" />
                    </button>
                </div>
                <div className="filter-buttons">
                    {["All", "BackEnd", "FrontEnd", "UI/UX"].map(category => (
                        <button
                            key={category}
                            className={`filter-button ${filter === category ? "active" : ""}`}
                            onClick={() => setFilter(category)}>
                            {category}
                        </button>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SkillsSection;