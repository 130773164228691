import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ProjectsLeftSideBar.css";
import Button from "../button/Button";
import { projectsData } from "../../data/ProjectsData";

const ProjectsLeftSideBar = () => { 

    const { projectKey } = useParams();
    const [activeProject, setActiveProject] = useState(projectKey || "gpt-trial-match");
    const [currentSection, setCurrentSection] = useState("Overview"); 
    const navigate = useNavigate();
    
    const handleButtonClick = (key) => {
        setActiveProject(key);
        navigate(`/projects/${key}`);
        handleFirstButtonScroll();
    };

    const handleSectionClick = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            const offset = 120; 
            const elementPosition = element.getBoundingClientRect().top + window.scrollY; 
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth', 
            });
        }
    };

    const handleFirstScroll = () => {
        window.scrollTo({
            top: "120px",
            behavior: 'auto', 
        });
    };

    const handleFirstButtonScroll = () => {
        window.scrollTo({
            top: "120px",
            behavior: 'smooth', 
        });
    };

    const handleScroll = () => {
        const sections = projectsData.flatMap(project => project.sections.map(section => section.id));
        const scrollPosition = window.scrollY + 120; 

        for (const section of sections) {
            const element = document.getElementById(section);
            if (element) {
                const { offsetTop, offsetHeight } = element;
                if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
                    setCurrentSection(section);
                    break;
                }
            }
        }
    };

    const throttle = (func, limit) => {
        let lastFunc;
        let lastRan;
        return function() {
            const context = this;
            const args = arguments;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(() => {
                    if ((Date.now() - lastRan) >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        };
    };

    useEffect(() => {
        handleFirstScroll();
        const throttledScroll = throttle(handleScroll, 100);
        window.addEventListener("scroll", throttledScroll);

        return () => {
            window.removeEventListener("scroll", throttledScroll);
        };
    }, []);

    return (
        <nav className="project-nav">
            <div className="pr-content">
                <ul className="project-list">
                    {projectsData.map((project) => (
                        <li key={project.key}>
                            <Button
                                text={project.header} 
                                className={`project-button`} 
                                isActive={activeProject === project.key}
                                onClick={() => handleButtonClick(project.key)}
                                avatar={project.avatarSRC}   
                            />
                            {activeProject === project.key && (
                                <nav className="navigation">
                                    <ul>
                                        {project.sections.map((section) => (
                                            <li key={section.id}>
                                                <button
                                                    onClick={() => handleSectionClick(section.id)}
                                                    className={`a-link ${currentSection === section.id ? 'highlight' : ''}`} 
                                                >
                                                    {section.id}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default ProjectsLeftSideBar;


