import Button from "../../../components/button/Button";

const handleDownload = () => {
    const pdfUrl = '/SleepTracker_SRS.pdf'; 
    window.open(pdfUrl, '_blank');
};

export const stOverviewSection = [
    <h2 >Overview</h2>,
    <p>
        <br/>
        This project was undertaken as part of the Information System Design course, focusing on the full lifecycle of information system creation. The objective was to mimic real-world development processes and experience the challenges, methodologies, and decision-making involved in delivering a robust and user-centric software solution.
        <br/><br/>
        The result is a prototype of SleepTracker, a system designed to help users monitor and optimize their sleep habits. The system aligns with user needs while adhering to industry standards for software design and development.
    </p>
];

export const stPlanningSection = [
    <h2>Planning and Requirement Analysis</h2>,
    <p>
        <br/><br/>
        To clearly define the intended purpose, functional and non-functional requirements, and constraints of SleepTracker app Software Requirement Specificaiton was developed. 
        It serves as a guide for stakeholders, including developers, testers, project managers, and clients, ensuring that everyone has a clear understanding of the system being developed.
    </p>,
    <Button onClick={handleDownload} text="Upload SRS" className="primary-button" ></Button>
]

export const stDesignSection = [
    <h2>System design</h2>,
    <h3>UI/UX design</h3>,
    <p>
        <br/>
        The developed UI/UX design meets the SRS description, focusing on usability, clarity and interactivity that enhances the overall user experience.
        <br/><br/>
        Positive user experience is ensured due to a simple and clear interface. A clear formation and logical structure is used. The interface does not force the user to "think". For example, the navigation is placed in the expected place for users. People immediately correctly recognize buttons, calls to action and links, and find them easily.
        <br/><br/>
        Graphs and metrics encourage users to regularly use the application and follow recommendations. Soothing dark colors and a focus on the night theme make the app pleasant for the user, especially before bed. Error messages help users troubleshoot a problem, for example by showing that they missed required fields to fill in an input form.
    </p>,
    <img src={"/assets/projects/content/sleep-tracker/uiux-1.png"} alt="UI/UX design" loading="lazy"></img>,
    <img src={"/assets/projects/content/sleep-tracker/uiux-2.png"} alt="UI/UX design" loading="lazy"></img>,
    <img src={"/assets/projects/content/sleep-tracker/uiux-3.png"} alt="UI/UX design" loading="lazy"></img>,
    <h3>System architecture</h3>,
    <h4>Database design</h4>,
    <p>
        To ensure efficient data management the data model and relationships were defined.
        <br/><br/>
        The visual representation of the entities within a system and their relationships to one another is presented on the Entity-Relationship Diagram (ERD) on the picture below.
    </p>,
    <img src={"/assets/projects/content/sleep-tracker/er-diagram.png"} alt="ER Diagram" loading="lazy"></img>,
    <p>
        <br/><br/>
        The ER diagram contains the following relationships between entities:
        <br/><br/>
        <span className="bold-italic">ONE-TO-MANY</span>
    </p>,
     <p className="list-style">
        One user (User) can have data for many nights (NightData), but the data for a specific night (NightData) can belong to only one user (User).
     </p>,
     <p className="list-style">
        One user (User) can have many alarms (Alarm), but a specific alarm (Alarm) belongs to one user (User).
     </p>,
     <p className="list-style">
        The data for a specific night (NightData) can include many indicators (Indicator), but a specific indicator (Indicator) belongs only to the data for that specific night (NightData).
     </p>,
     <p className="list-style">
        One type of indicators (IndicatorType) can have many indicators (Indicator), but a single indicator (Indicator) can belong only to one type (IndicatorType).
     </p>,
     <p>
        <br/><br/>
        <span className="bold-italic">MANY-TO-MANY</span>
     </p>,
     <p className="list-style">
        The data for a specific night (NightData) includes a list of tips (Tip), while each tip (Tip) can appear in the data for different nights (NightData). 
        <br/><br/>
        The additional table (NightDataTip) has been created to represent this relationship, which allows for different combinations.
        <br/><br/>
     </p>,
     <h4>System architecture based on ERD</h4>,
     <p>
        <br/>
        Based on develop ERD, architectural model of the system was designed. 
     </p>,
     <img src={"/assets/projects/content/sleep-tracker/class diagram.png"} alt="Class Diagram" loading="lazy"></img>,
     <p>
        <br/><br/>
        The class architecture for the design object consists of the following elements:
     </p>,
      <p className="list-style">
        <span className="bold-italic">Data Models</span> - classes representing database entities (User, Alarm, NightData, NightDataTip, Indicator, IndicatorType, Tip).
      </p>,
      <p className="list-style">
        <span className="bold-italic">_Repository Interfaces</span> - inherited from the standard JpaRepository {"<T, ID>"} interface to access database entities.
      </p>,
      <p className="list-style">
        <span className="bold-italic">_Service Interfaces</span> - focus on method prototypes for data access.
      </p>,
      <p className="list-style">
        <span className="bold-italic">ServiceImpl Classes</span> - implement the methods of the _Service interfaces using the _Repository for data access.
      </p>,
      <p className="list-style">
        <span className="bold-italic">_Controller Classes</span> - responsible for executing business logic using the _ServiceImpl classes.
      </p>
]

export const stDevelopmentSection = [
    <h2>Development</h2>,
    <p>
        <br/><br/>
        The back-end system development was based on the TDD (Test-Driven Development) methodology.
        <br/><br/>
        According to the TDD methodology, short development cycles were conducted: first, a unit test was written to cover the desired change, then the code was implemented to pass the test, and finally, the new code was refactored to meet the relevant standards.
        <br/><br/>
        Mockito and Junit libraries were used for unit testing.
        <br/><br/>
        One of the examples of the application of the TDD methodology is represented by the development of functionality related to the alarm clock.
    </p>,
    <h3>Test class creation</h3>,
    <p>
        <br/>
        First, the AlarmControllerTest class was created to implement unit tests for testing functions related to alarms. According to the Software Requirements Specification (SRS), support for the following functions was required:
    </p>,
    <p className="list-style">
        Setting an alarm
    </p>,
    <p className="list-style">
        Deleting an alarm by the specified ID
    </p>,
    <p className="list-style">
        Activating an alarm by the specified ID
    </p>,
    <p className="list-style">
        Deactivating an alarm by the specified ID
    </p>,
    <p className="list-style">
        Retrieving all alarms for the specified user ID.
    </p>,
    <h3>Test class variables initialization</h3>,
    <p>
        <br/>
        According to the class architecture described above, to implement the business logic, the Controller class must use _ServiceImplementation. 
        In the AlarmControllerTest class, corresponding variables were initialized:
    </p>,
    <img src={"/assets/projects/content/sleep-tracker/class-variables.png"} alt="Class Variables" loading="lazy"></img>,
    <p>
        <br/><br/>
        Using mock() indicates that the created object externally resembles AlarmServiceImplementation. By default, it does nothing and returns a null value when called. In the test methods, the necessary actions will be simulated using static when for this object.
    </p>,
     <h3>Controller class creation</h3>,
     <p>
        <br/>
        Next, the AlarmController class was created. The "skeletons" of the class methods were developed, 
        which were implemented after writing the unit tests.
     </p>,
     <img src={"/assets/projects/content/sleep-tracker/alarm-controller.png"} alt="Alarm Controller" loading="lazy"></img>,
     <h3>Unit tests</h3>,
     <p>
        <br/>
        A series of unit tests were written to cover the desired changes. These tests evaluated both the successful execution of the code and the ability to return appropriate failure responses under certain conditions.
        <br/><br/>
        For example, below the unit test for testing the success of setting the alarm clock is presented.
     </p>,
     <img src={"/assets/projects/content/sleep-tracker/unit-test.png"} alt="Unit Test" loading="lazy"></img>,
     <p>
        <br/><br/>
        As a result of running the unit tests without implementing the actual logic, all tests failed, which was expected:
     </p>,
     <img src={"/assets/projects/content/sleep-tracker/unit-test-failed.png"} alt="Unit Test Failed" loading="lazy"></img>,
     <h3>Logic implementation</h3>,
     <p>
        <br/>
        In the next stage, the logic for the methods of the AlarmController class was implemented.
     </p>,
     <img src={"/assets/projects/content/sleep-tracker/unit-test-success.png"} alt="Unit Test Success" loading="lazy"></img>,
     <p>
        <br/><br/>
        As a result, the previously written unit tests passed, indicating the correctness of the implemented methods.
     </p>
]

export const stConclusionsSection = [
    <h2>
        Conclusions
    </h2>,
    <p>
        <br/><br/>
        The final deliverable is a functional prototype of SleepTracker, a system designed to assist users in tracking and improving their sleep habits. 
        <br/><br/>
        This project was a pivotal learning experience, bridging the gap between theoretical concepts and practical applications. By experiencing the complete system lifecycle, this project served as an excellent foundation for future endeavors in system design and development.
    </p>
]

export const SleepTrackerHeader = "SleepTracker"

export const SleepTrackerDescription = "System for monitoring, analyzing and improving sleep quality."

export const SleepTrackerAvatarSRC = "/assets/projects/alarm.svg"